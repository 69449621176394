<template>
    <div class="card">
      <div class="card-header">
        <div class="row">
          <div class="col">Requête exécutée</div>
          <div class="col-md-auto">
            <button
              type="button"
              class="btn-close float-end"
              @click="$emit('hideDeviceResult')"
            ></button>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="">
          <table class="table table-sm">
            <thead>
              <th scope="col">Ident</th>
              <th scope="col">User Id</th>
              <th scope="col">ICCID</th>
              <th scope="col">MSISDN</th>
              <th scope="col">Agence</th>
              <th scope="col">Etat</th>
            </thead>
            <tbody>
              <tr
                v-for="retour in devicesRetour"
                :key="retour.ident"
                :class="retour.class"
              >
                <td>{{ retour.payload.ident }}</td>
                <td>{{ retour.payload.userId }}</td>
                <td>{{ retour.payload.iccid }}</td>
                <td>{{ retour.payload.msisdn }}</td>
                <td>{{ retour.payload.agency }}</td>
                <td>{{ retour.statusText }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
</template>


<script>
export default {
  props: ["devicesRetour"],
  data() {
    return {

    };
  },
  methods: {

  },
};
</script>
