<template>
  <div>
    <DoughnutChart :chartData="synthesis"></DoughnutChart>
  </div>
</template>

<script>
import DoughnutChart from "./synthesisDevicesChart";

export default {
  name: "App",
  components: {
    DoughnutChart,
  },

  data() {
    return {
      calculated: {},
      status_possible: [],
      type_possible: []
    };
  },
  computed: {
    synthesis() {
      this.calculeTable(this.$store.state.synthesis)
      let synthesis = this.formatData();
      return synthesis;
    },
  },
  methods: {
    add_status(item) {
      //cree un array unique avec les status possibles
      if (this.status_possible.indexOf(item) === -1) {
        this.status_possible.push(item);
      }
    },
    add_type(item) {
      //cree un array unique avec les status possibles
      if (this.type_possible.indexOf(item) === -1) {
        this.type_possible.push(item);
      }
    },
    calculeTable(synthesis) {
      //recherche tous les status et types existants
      for (const device of synthesis.devices) {
        this.add_status(device.status);
        this.add_type(device.type_name);
      }
      this.type_possible = this.type_possible.sort((a, b) => (a > b ? 1 : -1));
      for (const type of this.type_possible) {
        this.calculated[type] = {};
        for(const statut of this.status_possible){
           this.calculated[type][statut] = 0
        }
      }
      for (const device of synthesis.devices) {
        this.calculated[device.type_name][device.status] = device.count;
      }
      for (const type of this.type_possible) {
        this.calculated[type]["total"] = 0
        for(const statut of this.status_possible){
           this.calculated[type]["total"] += this.calculated[type][statut]
        }
      }
    },
    
    formatData() {
      let chartData = {
        labels: [],
        datasets: [
          {
            labels: [],
            backgroundColor:  ["#d00000","#ffba08","#3f88c5","#032b43","#136f63"],
            data: [],
          },
        ],
      };

      for (const device in this.calculated) {
        chartData.labels.push(device);
        chartData.datasets[0].data.push(this.calculated[device].total);
      }
      return chartData;
    },
  },
};
</script>

   