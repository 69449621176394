export default {
  methods: {
    filterList(list, fields, search) {
      let short_list = [];
      for (let field of fields) {
        if (search != "") {
          short_list = short_list.concat(
            list.filter((data) => data[field].includes(search))
          );
          if (typeof short_list == "undefined") {
            short_list = {};
          }
        } else {
          short_list = list;
        }
      }
      let short = short_list.filter((item, index) => {
        return short_list.indexOf(item) == index;
      });
      return short 
    },
    orderList(list, field, asc = true) {
      if (!asc) {
        if (!isNaN(typeof parseInt(list[0][field]))) {
          list.sort((a, b) => a[field] - b[field]);
        } else {
          list.sort((a, b) => (a[field] < b[field] ? 1 : -1));
        }
      } else {
        if (!isNaN(typeof parseInt(list[0][field]))) {
          list.sort((a, b) => b[field] - a[field]);
        } else {
          list.sort((a, b) => (a[field] > b[field] ? 1 : -1));
        }
      }
    },
    getPages(list, per_page) {
      const items = list.length;
      let page_nb = Math.floor(items / per_page);
      if (items % per_page > 0) {
        page_nb += 1;
      }
      const pages = Array.from({ length: page_nb }, (_, i) => 1 + i);
      return pages
    },
    pagination(list, per_page, page) {
      const debut = (page - 1) * per_page;
      const fin = page * per_page;
      let short_list = list.slice(debut, fin);
      return short_list
    },
  }
};