import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueMeta from 'vue-meta';

import authentication from '@/plugins/authentication';
import { HTTP } from "@/middleware/http-common";


// import 'bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';

// import 'sass/custom.scss'

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faUserSecret } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(faUserSecret)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueMeta);

Vue.config.productionTip = false



//authentication with keycloack
Vue.use(authentication)

Vue.$keycloak
    .init({
        onLoad: 'login-required',
        checkLoginIframe: false
    })
    .then(() => {
        // HTTP.defaults.headers.common = { 'Authorization': `Bearer ${Vue.$keycloak.token}` }
        HTTP.defaults.headers.common = { 'Authorization': `Bearer ${Vue.$keycloak.token}` }

        new Vue({
            router,
            store,
            render: h => h(App),
        }).$mount('#app');

        //periodical test for token refresh
        setInterval(() => {
            Vue.$keycloak.updateToken(10).then((refreshed) => {
                if (refreshed) {
                    // console.log('Token refreshed ' + refreshed);
                    // HTTP.defaults.headers.common = { 'Authorization': `Bearer ${Vue.$keycloak.token}` }
                    // console.log('Token refreshed');

                } else {
                    // console.log('Token not refreshed, valid for ' + Math.round(Vue.$keycloak.tokenParsed.exp + Vue.$keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
                    // console.log(Vue.$keycloak.tokenParsed)
                }
            }).catch(() => {
                console.log('Failed to refresh token');
            });
        }, 10000)
    });

// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')


