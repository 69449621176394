<template>
  <div class="admin">
    <div class="row">
      <div class="col"><h1>Administration</h1></div>
      <div class="col-md-auto"></div>
    </div>
    <div class="row">
      <div class="col">
        <div class="card mb-3">
          <div class="card-body">
            <nav>
              <div class="nav nav-pills nav-fill" id="nav-tab" role="tablist">
                <div
                  class="nav-item"
                  role="presentation"
                  v-if="accessCheck('users')"
                >
                  <button
                    class="nav-link active"
                    id="nav-users-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-users"
                    type="button"
                    role="tab"
                    aria-controls="nav-home"
                    aria-selected="true"
                  >
                    Utilisateurs
                  </button>
                </div>
                <div
                  class="nav-item"
                  role="presentation"
                  v-if="accessCheck('devicesType')"
                >
                  <button
                    class="nav-link"
                    id="nav-devices-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-devices"
                    type="button"
                    role="tab"
                    aria-controls="nav-devices"
                    aria-selected="false"
                  >
                    Types Transmetteur
                  </button>
                </div>
                <div
                  class="nav-item"
                  role="presentation"
                  v-if="accessCheck('profiles')"
                >
                  <button
                    class="nav-link"
                    id="nav-profiles-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-profiles"
                    type="button"
                    role="tab"
                    aria-controls="nav-profile"
                    aria-selected="false"
                  >
                    Profils
                  </button>
                </div>
                <div
                  class="nav-item"
                  role="presentation"
                  v-if="accessCheck('agencies')"
                >
                  <button
                    class="nav-link"
                    id="nav-agencies-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#nav-agencies"
                    type="button"
                    role="tab"
                    aria-controls="nav-contact"
                    aria-selected="false"
                  >
                    Agences
                  </button>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <div class="tab-content" id="nav-tabContent">
          <div
            class="tab-pane fade show active"
            id="nav-users"
            role="tabpanel"
            aria-labelledby="nav-users-tab"
            v-if="accessCheck('users')"
          >
            <usersEdit></usersEdit>
          </div>
          <div
            class="tab-pane fade show"
            id="nav-devices"
            role="tabpanel"
            aria-labelledby="nav-devices-tab"
            v-if="accessCheck('devicesType')"
          >
            <deviceTypeEdit></deviceTypeEdit>
          </div>
          <div
            class="tab-pane fade"
            id="nav-profiles"
            role="tabpanel"
            aria-labelledby="nav-profiles-tab"
            v-if="accessCheck('profiles')"
          >
            <profilesEdit></profilesEdit>
          </div>
          <div
            class="tab-pane fade"
            id="nav-agencies"
            role="tabpanel"
            aria-labelledby="nav-agencies-tab"
            v-if="accessCheck('agencies')"
          >
            <agenciesEdit></agenciesEdit>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import usersEdit from "../components/admin/usersEdit";
import deviceTypeEdit from "../components/admin/deviceTypeEdit";
import agenciesEdit from "../components/admin/agenciesEdit";
import profilesEdit from "../components/admin/profilesEdit";

import accessCheck from "../mixins/accessCheck.js";

export default {
    metaInfo: {
    title: "Administration",
  },
  mixins: [accessCheck],
  components: {
    usersEdit,
    deviceTypeEdit,
    agenciesEdit,
    profilesEdit,
  },
  data() {
    return {};
  },

  // Fetches posts when the component is created.
  created() {},

  methods: {},
};
</script>
