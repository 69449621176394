<template>
  <div>
    <div class="row">
      <div class="col">
        <h1>Synthèse du parc</h1>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col">Transmetteurs</div>
              <div class="col-md-auto"></div>
              <div class="col-md-auto"></div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col">
                <synthesis-devices :key="loadingStatus"></synthesis-devices>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col">SIMS</div>
              <div class="col-md-auto"></div>
              <div class="col-md-auto"></div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col">
                <synthesis-sims :key="loadingStatus"></synthesis-sims>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import synthesisDevices from "../components/home/synthesisDevices";
// import synthesisSims from "../components/home/synthesisSims";

export default {
  components: {
    synthesisDevices,
    // synthesisSims,
  },
  data() {
    return {};
  },
  created() {
    this.$store.dispatch("fetchSynthesis");
  },
  computed: {
    loadingStatus() {
      return this.$store.getters.getLoadingStatus;
    },
  },
};
</script>