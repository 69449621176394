<template>
  <div class="">
    <nav class="navbar navbar-expand-md navbar-light fixed-top bg-light">
      <div class="container-fluid">
        <a class="navbar-brand" href="#">PV Inventaire</a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
          <ul class="navbar-nav me-auto mb-2 mb-md-0">
            <li class="nav-item active">
              <router-link to="/" class="nav-link">Accueil</router-link>
            </li>
            <li class="nav-item" v-if="accessCheck('devices')">
              <router-link to="/devices" class="nav-link"
                >Transmetteurs</router-link
              >
            </li>
            <li class="nav-item" v-if="accessCheck('sims')">
              <router-link to="/sims" class="nav-link">SIM</router-link>
            </li>
            <li class="nav-item" v-if="accessCheck('beacons')">
              <router-link to="/beacons" class="nav-link">Balises</router-link>
            </li>
            <li
              class="nav-item"
              v-if="
                accessCheck('users') ||
                accessCheck('devicesType') ||
                accessCheck('profiles') ||
                accessCheck('agencies')
              "
            >
              <router-link to="/admin" class="nav-link"
                >Admin</router-link
              >
            </li>
          </ul>
          <div class="d-flex">
            <button class="btn btn-outline">
              <router-link to="/user" class="nav-item nav-link">{{ user.full_name }}</router-link>
            </button>
          </div>
        </div>
      </div>
    </nav>
    <div class="text-center" v-if="loadingStatus">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <main class="container">
      <div class="bg-light p-5">
        <router-view></router-view>
      </div>
    </main>
  </div>
</template>

<script>
import accessCheck from "./mixins/accessCheck.js";

export default {
  name: "App",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Accueil",
    // all titles will be injected into this template
    titleTemplate: "PV Inventaire - %s",
  },
  mixins: [accessCheck],
  data() {
    return {};
  },
    beforeCreate() {
        this.$store.dispatch("fetchUser")
    },
  created() {
    // this.dataReady = true;
  },
  computed: {
    loadingStatus() {
      return this.$store.getters.getLoadingStatus;
    },
    user() {
      return this.$store.getters.getUser;
    },
  },
};
</script>


<style lang="scss">
body { padding-top: 40px; }
@media screen and (max-width: 768px) {
    body { padding-top: 0px; }
}

.loading-box {
  z-index: 9;
  position: relative;
  height: 10px;
  border: 2px solid #ededed;
  overflow: hidden;
}
.loader {
  width: 100%;
  height: 100%;
  position: absolute;
  background: linear-gradient(45deg, #b6b5ff, #3f88c5);
  left: 0%;
}
.loader {
  left: -100%;
  animation: load 2s linear infinite;
}

@keyframes load {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
</style>
