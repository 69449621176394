import Vue from 'vue'
import Vuex from 'vuex'
import { HTTP } from "../middleware/http-common";
import moment from 'moment'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loadingStatus: false,
    loadingSynthesis: false,
    loadingSims: false,
    loadingDevices: false,
    loadingBeacons: false,
    agencies: ["Base"],
    profiles: ["Base"],
    deviceTypes: ["Base"],
    devices: ["Base"],
    sims: ["Base"],
    beacons: ["Base"],
    user: {},
    synthesis: {}
  },
  getters: {
    getLoadingStatus: (state) => {
      return (state.loadingSynthesis || state.loadingSims || state.loadingDevices || state.loadingBeacons || state.loadingStatus)
    },
    getAgencies: (state) => state.agencies,
    getProfiles: (state) => state.profiles,
    getDeviceTypes: (state) => state.deviceTypes,
    getSynthesis: (state) => state.synthesis,
    getDevices: (state) => state.devices,
    getSims: (state) => state.sims,
    getBeacons: (state) => state.beacons,
    getUser: (state) => state.user
  },
  mutations: {
    SET_LOADINGSTATUS(state, loadingStatus) {
      state.loadingStatus = loadingStatus
    },
    SET_LOADINGSYNTHESIS(state, loadingSynthesis) {
      state.loadingSynthesis = loadingSynthesis
    },
    SET_LOADINGSIMS(state, loadingSims) {
      state.loadingSims = loadingSims
    },
    SET_LOADINGDEVICES(state, loadingDevices) {
      state.loadingDevices = loadingDevices
    },
    SET_LOADINGBEACONS(state, loadingBeacons) {
      state.loadingBeacons = loadingBeacons
    },
    SET_AGENCIES(state, agencies) {
      state.agencies = agencies
    },
    SET_PROFILES(state, profiles) {
      state.profiles = profiles
    },
    SET_DEVICETYPES(state, deviceTypes) {
      state.deviceTypes = deviceTypes
    },
    SET_SYNTHESIS(state, synthesis) {
      state.synthesis[synthesis['type']] = synthesis['value']
    },
    SET_DEVICES(state, devices) {
      state.devices = devices
    },
    SET_SIMS(state, sims) {
      state.sims = sims
    },
    SET_BEACONS(state, beacons) {
      state.beacons = beacons
    },
    SET_USER(state, user) {
      state.user = user
    }
  },
  actions: {
    change_loading_status({ commit }, bool){
      commit('SET_LOADINGBEACONS', bool)
    },
    fetchBeacons({ commit }) {
      commit('SET_LOADINGBEACONS', true)
      const promise = HTTP.get('beacons')
      promise.then((response) => {
        commit('SET_BEACONS', response.data)
        commit('SET_LOADINGBEACONS', false)
      }).catch(error => console.log(error))
    },
    fetchAgencies({ commit }) {
      commit('SET_LOADINGSTATUS', true)
      // gere le cas d'un utilisateur monoagence 
      if (this.getters.getUser.policies.other.all_agencies == "True") {
        const promise = HTTP.get('agencies')
        promise.then((response) => {
          commit('SET_AGENCIES', response.data)
          commit('SET_LOADINGSTATUS', false)
          console.log(response.data)
        }).catch(error => console.log(error))        
      }else{
        const agencies = [{
          "name":this.getters.getUser.agency,
          "id":this.getters.getUser.agency_id
        }]
        commit('SET_AGENCIES', agencies)
      }

    },
    fetchProfiles({ commit }) {
      commit('SET_LOADINGSTATUS', true)
      const promise = HTTP.get('users/profiles')
      promise.then((response) => {
        commit('SET_PROFILES', response.data)
        commit('SET_LOADINGSTATUS', false)
      }).catch(error => console.log(error))
    },
    fetchDeviceTypes({ commit }) {
      commit('SET_LOADINGSTATUS', true)
      const promise = HTTP.get('devices/types')
      promise.then((response) => {
        commit('SET_DEVICETYPES', response.data)
        commit('SET_LOADINGSTATUS', false)
      }).catch(error => console.log(error))
    },
    fetchDevices({ commit }) {
      commit('SET_LOADINGDEVICES', true)
      const promise = HTTP.get('devices?per_page=999999')
      promise.then((response) => {
        commit('SET_DEVICES', response.data)
        commit('SET_LOADINGDEVICES', false)
      }).catch(error => console.log(error))
    },
    fetchSims({ commit }) {
      commit('SET_LOADINGSIMS', true)
      const promise = HTTP.get('sims')
      promise.then((response) => {
        commit('SET_SIMS', response.data)
        commit('SET_LOADINGSIMS', false)
      }).catch(error => console.log(error))
    },
    fetchSynthesis({ commit }) {
      commit('SET_LOADINGSYNTHESIS', true)
      let promise = HTTP.get('/global/devices/all')
      promise.then((response) => {
        const synthesis = {
          "type": 'devices',
          "value": response.data
        }
        commit('SET_SYNTHESIS', synthesis)
        commit('SET_LOADINGSYNTHESIS', false)
      }).catch(error => console.log(error))
      // commit('SET_LOADINGSYNTHESIS', true)
      // promise = HTTP.get('/global/sims/all')
      // promise.then((response) => {
      //   const synthesis = {
      //     "type": 'sims',
      //     "value": response.data
      //   }
      //   commit('SET_SYNTHESIS', synthesis)
      //   commit('SET_LOADINGSYNTHESIS', false)
      // }).catch(error => console.log(error))
    },
    fetchUser({ commit }) {
      // get user information from user and profile
      commit('SET_LOADINGSTATUS', true)
      let user = {
        "kc_name": Vue.$keycloak.tokenParsed.given_name,
        "kc_family_name": Vue.$keycloak.tokenParsed.family_name,
        "kc_full_name": Vue.$keycloak.tokenParsed.name,
        "mail": Vue.$keycloak.tokenParsed.email,
        "email_verified": Vue.$keycloak.tokenParsed.email_verified,
        "time_auth": Vue.$keycloak.tokenParsed.auth_time,
        "datetime_auth": moment.unix(Vue.$keycloak.tokenParsed.auth_time).format(),
        "time_exp": Vue.$keycloak.tokenParsed.exp,
        "datetime_exp": moment.unix(Vue.$keycloak.tokenParsed.exp).format(),
        "time_iat": Vue.$keycloak.tokenParsed.iat,
        "datetime_iat": moment.unix(Vue.$keycloak.tokenParsed.iat).format()
      }
      let userpromise = HTTP.get('/users/' + user.mail)
      userpromise.then((response) => {
        let user_data = response.data
        let profilepromise = HTTP.get('/users/profiles/' + user_data.profile_id)
        profilepromise.then((response) => {
          let profile_data = response.data
          user.full_name = user_data.name
          user.status = user_data.status
          user.agency = user_data.agency
          user.agency_id = user_data.agency_id
          user.profile = user_data.profile
          user.policies = profile_data
          user.allow = (user.status == 'active') && user.email_verified
          commit('SET_USER', user)
        }).catch(error => console.log(error))

      }).catch(error => console.log(error))
      commit('SET_LOADINGSTATUS', false)
    },
    logoutUser({ commit }) {
      commit('SET_LOADINGSTATUS', true)
      commit('SET_USER', { "allow":false })
    },
  },
  modules: {
  }
})
