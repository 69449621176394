var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-align":"center"}},[_c('div',{staticStyle:{"max-width":"75px","float":"left"}},[_c('select',{staticClass:"form-select",attrs:{"id":"perPage","aria-label":"Nombre de device par page","required":""},on:{"change":function($event){return _vm.onChange($event)}}},_vm._l((_vm.per_page_options),function(nb_per_page){return _c('option',{key:nb_per_page,domProps:{"value":nb_per_page}},[_vm._v(" "+_vm._s(nb_per_page)+" ")])}),0)]),_c('div',{staticClass:"col-md-auto",staticStyle:{"margin-right":"75px"}},[_c('div',{staticClass:"btn-group",attrs:{"role":"group","aria-label":"Pager"}},[_c('button',{staticClass:"btn",class:{
          'btn-outline-primary': _vm.page,
        },attrs:{"type":"button"},on:{"click":function($event){return _vm.goToFirstPage()}}},[_vm._v(" First ")]),_c('button',{staticClass:"btn",class:{
          'btn-outline-primary': _vm.page,
        },attrs:{"type":"button"},on:{"click":function($event){return _vm.goToPreviousPage(_vm.page)}}},[_vm._v(" Previous ")]),_vm._l((_vm.charge_ten_array(_vm.page, _vm.pages)),function(lapage){return _c('button',{key:lapage,staticClass:"btn",class:{
          'btn-secondary': lapage == _vm.page,
          'btn-outline-primary': lapage != _vm.page,
        },attrs:{"type":"button"},on:{"click":function($event){return _vm.goToPage(lapage)}}},[_vm._v(" "+_vm._s(lapage)+" ")])}),_c('button',{staticClass:"btn",class:{
          'btn-outline-primary': _vm.page,
        },attrs:{"type":"button"},on:{"click":function($event){return _vm.goToNextPage(_vm.page, _vm.pages)}}},[_vm._v(" Next ")]),_c('button',{staticClass:"btn",class:{
          'btn-outline-primary': _vm.page,
        },attrs:{"type":"button"},on:{"click":function($event){return _vm.goToLastPage(_vm.pages)}}},[_vm._v(" Last ")])],2),_c('div',{staticClass:"col-md-auto"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }