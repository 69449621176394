<template>
<div>
    <div class="row ">
      <div class="col mb-3">
        <div class="card">
          <div class="card-body">
            <input
              class="form-control me-2"
              v-model="search"
              type="search"
              placeholder="Rechercher (Ident, Transmetteur, Agence)"
              aria-label="Search"
            />
          </div>
        </div>
      </div>
    </div>
  <div class="card">
    <div class="card-header">
      Inventaire de transmetteurs
    </div>
    <div class="card-body">
      <div>
        <table class="table">
          <thead>
            <tr class="tr-class-1 pointer">
              <th @click="orderField('ident')">Ident</th>
              <th @click="orderField('userId')">Transmetteur</th>
              <th @click="orderField('type')">Type</th>
              <th @click="orderField('agency')">Agence</th>
              <th @click="orderField('status')">Statut</th>
              <th v-if="accessCheck('devices', 'update')">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="shown_devices.length == 0">Pas de transmetteur trouvé</tr>
            <tr
              v-for="device in shown_devices"
              :key="device.ident"
              data-toggle="tooltip"
              data-placement="top"
              :title="device.comment"
            >
              <td>{{ device.ident }}</td>
              <td>{{ device.userId }}</td>
              <td>{{ device.type }}</td>
              <td>{{ device.agency }}</td>
              <td>{{ device.status }}</td>
              <td v-if="accessCheck('devices', 'update')">
                <button
                  v-on:click="editDevice(device)"
                  type="button"
                  class="btn btn-outline-primary btn-sm"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-pencil"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"
                    />
                  </svg>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <pageSelector
        :per_page_options="per_page_options"
        :per_page="per_page"
        :page="page"
        :pages="pages"
        @choosen-page="goToPage"
        @per-page="showPerPage"
      ></pageSelector>
    </div>
  </div></div>
</template>

<style>
.pointer {
  cursor: pointer;
}
</style>

<script>
import getPages from "../../mixins/manageList.js";
import orderList from "../../mixins/manageList.js";
import pagination from "../../mixins/manageList.js";
import filterList from "../../mixins/manageList.js";

import pageSelector from "../pageSelector";

import accessCheck from "../../mixins/accessCheck.js"


export default {
  components: {
    pageSelector,
  },
  mixins: [orderList, getPages, pagination, filterList, accessCheck],
  props: ["devices"],
  data() {
    return {
      search: "",
      page: 1,
      per_page: 10,
      per_page_options: [10, 50, 100],
      order: true,
    };
  },
  watch: {
    search() {
      this.page=1
    }
  },
  created() {
    this.$store.dispatch("fetchDevices");
    this.orderField("userId");
  },
  computed: {
    listDevices() {
      return this.$store.getters.getDevices;
    },
    shown_devices() {
      this.pagination(this.listDevices, this.per_page, this.page);
      let device = this.pagination(
        this.filtered_devices,
        this.per_page,
        this.page
      );
      return device;
    },
    filtered_devices() {
      return this.filterList(
        this.listDevices,
        ["ident", "userId", "agency"],
        this.search
      );
    },
    pages() {
      return this.getPages(this.filtered_devices, this.per_page);
    },
  },
  methods: {
    editDevice(device) {
      this.$emit("selectedDevice", device);
    },
    orderField(field) {
      this.orderList(this.listDevices, field, !this.order);
      this.order = !this.order;
    },
    goToPage(page) {
      this.page = page;
    },
    showPerPage(per_page) {
      this.per_page = per_page;
      this.page = 1;
    },
  },
};
</script>