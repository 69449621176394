<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div calss="col">
        <table class="table">
          <thead>
            <tr class="tr-class-1 pointer">
              <th @click="orderField('name')">Nom</th>
              <th @click="orderField('mail')">Mail</th>
              <th @click="orderField('agency')">Agence</th>
              <th @click="orderField('profile')">Profil</th>
              <th @click="orderField('status')">Statut</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="user in listUsers"
              :key="user.mail"
              data-toggle="tooltip"
              data-placement="top"
            >
              <td v-if="user.mail == changed_user.mail">
                <input
                  id="name"
                  v-model="changed_user.name"
                  class="form-control m-0"
                  required
                />
              </td>
              <td v-else>{{ user.name }}</td>

              <td v-if="user.mail == ''">
                <input
                  id="mail"
                  v-model="new_mail"
                  class="form-control"
                  required
                />
              </td>
              <td v-else >
                {{ user.mail }}
              </td>
              <td v-if="user.mail == changed_user.mail">
                <select
                  id="agency"
                  v-model="changed_user.agency_id"
                  class="form-select no-padding"
                  aria-label="Agence"
                  required
                >
                  <option
                    v-for="agency in agencies"
                    :value="agency.id"
                    :key="agency.name"
                  >
                    {{ agency.name }}
                  </option>
                </select>
              </td>
              <td v-else>{{ user.agency }}</td>

              <td v-if="changed_user.mail == user.mail">
                <select
                  id="profile"
                  v-model="changed_user.profile_id"
                  class="form-select"
                  aria-label="Profil"
                  required
                >
                  <option
                    v-for="profile in profiles"
                    :value="profile.id"
                    :key="profile.name"
                  >
                    {{ profile.name }}
                  </option>
                </select>
              </td>
              <td v-else>{{ user.profile }}</td>
              <td v-if="user.mail == changed_user.mail">
                <select
                  id="status"
                  v-model="changed_user.status"
                  class="form-select no-padding"
                  aria-label="Statut"
                  required
                >
                  <option
                    v-for="status in statusList"
                    :value="status"
                    :key="status"
                  >
                    {{ status }}
                  </option>
                </select>
              </td>
              <td v-else>{{ user.status }}</td>
              <td v-if="changed_user.mail != user.mail">
                <button
                  v-on:click="chooseUser(user)"
                  type="button"
                  class="btn btn-outline-primary btn-sm"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-pencil"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"
                    />
                  </svg>
                </button>
              </td>
              <td v-else>
                <div class="btn-group" role="group" aria-label="Basic example">

                <button
                  v-on:click="editUser(user)"
                  type="button"
                  class="btn btn-primary btn-sm btn-success"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-check"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
                    />
                  </svg>
                </button>
                <button
                  v-on:click="editUser(user)"
                  type="button"
                  class="btn btn-outline-primary btn-sm"
                >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-x"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                      />
                    </svg>
                </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        </div>
      </div>
      <div class="row">
      <div class="col"></div>
      <div class="col-md-auto">

        <button type="button" class="btn btn-outline-primary" v-on:click="createUser()">
          Nouveau
        </button>
      </div>
      </div>
    </div>
  </div>
</template>

<style>
.pointer {
  cursor: pointer;
}
input {
    color: #000;
  background: crimson;
  padding: 0;
}
</style>

<script>
import { HTTP } from "../../middleware/http-common";

export default {
  data() {
    return {
      listUsers: [],
      statusList:['active', 'suspended', 'disable'],
      order: {
        name: "up",
      },
      changed_user: {},
      new_mail: "",
      new_user: {
        new: true,
        name: "",
        mail: "",
        agency: "",
        profile: "",
      },
    };
  },
  computed: {
    agencies() {
      return this.$store.state.agencies;
    },
    profiles() {
      return this.$store.state.profiles;
    },
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      //get users
      try {
        const response = await HTTP.get(`users`);
        this.listUsers = [];
        this.listUsers.push(...response.data);
      } catch (e) {
        this.errors.push(e);
      }
    },
    createUser() {
      this.changed_user = { ...this.new_user };
      this.listUsers.unshift(this.changed_user);
    },
    editUser(user=null) {
      if (user){

      try {
        let endpoint = "";
        if (user.new) {
          user.mail = this.new_mail.toLowerCase();
          endpoint = "users";
          delete user.profile;
          delete user.agency;
          delete user.new;
        } else {
          endpoint = "users/" + user.mail;
          delete user.mail;
          delete user.profile;
          delete user.agency;
        }

        HTTP.post(endpoint, user).then(
          function (response) {
            console.log(response);
            if (response.status == 200) {
              this.getList();
            } else {
              console.log("on alerte");
            }
          }.bind(this)
        );
      } catch (error) {
        console.log("on alerte");
      }   
      this.changed_user = {}

      }
    },
    orderField(field) {
      console.log(this.order);
      console.log(typeof parseInt(this.listUsers[0][field]));
      console.log(parseInt(this.listUsers[0][field]));

      if (this.order[field] == "up") {
        if (!isNaN(typeof parseInt(this.listUsers[0][field]))) {
          this.listUsers.sort((a, b) => a[field] - b[field]);
        } else {
          this.listUsers.sort((a, b) => (a[field] < b[field] ? 1 : -1));
        }
        this.order[field] = "down";
        console.log("up");
      } else {
        if (!isNaN(typeof parseInt(this.listUsers[0][field]))) {
          this.listUsers.sort((a, b) => b[field] - a[field]);
        } else {
          this.listUsers.sort((a, b) => (a[field] > b[field] ? 1 : -1));
        }
        this.order[field] = "up";
        console.log("down");
      }
    },
    chooseUser(user) {
      this.changed_user = user;
      console.log(this.changed_user);
    },
  },
};
</script>
