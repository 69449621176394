<template>
  <div>
    <div class="card">
      <div class="card-body">
        <form>
          <div class="mb-3">
            <div class="col"><h5>Nom du profil :</h5></div>

            <input
              id="name"
              v-model="current_profile.name"
              class="form-control"
              required
            />
          </div>
          <div class="mb-3">
            <div class="col"><h5>Commentaire :</h5></div>

            <textarea
              class="form-control"
              id="comment"
              rows="3"
              data-toggle="tooltip"
              data-placement="top"
              title="Un identifiant par ligne"
              placeholder="I"
              v-model="current_profile.comment"
            ></textarea>
          </div>

          <div class="mb-3">
            <div class="col"><h5>Droits d'accès :</h5></div>

            <div class="row">
              <div calss="col">
                <table
                  class="table"
                >
                  <thead>
                    <tr class="tr-class-1 pointer">
                      <th>Ressource</th>
                      <th
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-bs-title="Tooltip on top"
                      >
                        Droits actifs
                        <small class="text-muted">Cliquer pour supprimer</small>
                      </th>
                      <th></th>
                      <th>
                        Droits possibles
                        <small class="text-muted">Cliquer pour ajouter</small>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="policy in default_policies" :key="policy">
                      <td>{{ policy }}</td>
                      <td>
                        <button
                          type="button"
                          class="btn btn-primary btn-action mx-2"
                          v-for="action in current_profile.policies[policy]"
                          :key="current_profile.policies[policy][action]"
                          @click="removeAction(policy, action)"
                          style="
                            --bs-btn-padding-y: 0.25rem;
                            --bs-btn-padding-x: 0.5rem;
                            --bs-btn-font-size: 0.75rem;
                          "
                        >
                          {{ action }}
                        </button>
                      </td>
                      <td>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-arrow-left"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8z"
                          />
                        </svg>
                      </td>
                      <td>
                        <button
                          type="button"
                          class="btn btn-outline-primary btn-action mx-2"
                          v-for="action in default_actions"
                          :key="action"
                          @click="addAction(policy, action)"
                          style="
                            --bs-btn-padding-y: 0.25rem;
                            --bs-btn-padding-x: 0.5rem;
                            --bs-btn-font-size: 0.75rem;
                          "
                        >
                          {{ action }}
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <div class="row">
              <div class="col"><h5>Droits spécifiques :</h5></div>
            </div>
            <div class="row">
              <div class="col">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="current_profile.other.all_agencies"
                    id="flexCheckDefault"
                  />
                  <label class="form-check-label" for="flexCheckDefault">
                    Accéder à l'ensemble des agences
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-3">
            <div class="row">
              <div class="col">
                <div class="d-grid gap-2">
                  <button
                    type="button"
                    class="btn btn-primary"
                    v-on:click="saveProfil()"
                  >
                    Enregistrer le profil
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>


<script>
import { HTTP } from "../../middleware/http-common";


export default {
  props: ["profile"],
  data() {
    return {
      default_profile: {
        name: "",
        comment: "",
        policies: {},
        other: {
          all_agencies: false,
        },
      },
      current_profile: {},
      default_actions: ["create", "read", "update"],
      default_policies: [
        "agencies",
        "agency",
        "beacon",
        "beacons",
        "devices",
        "device",
        "devicesType",
        "deviceType",
        "profiles",
        "profile",
        "sims",
        "sim",
        "users",
        "user",
      ],
    };
  },
  created() {
    for (let policy of this.default_policies) {
      this.default_profile.policies[policy] = [];
    }
    this.current_profile = { ...this.default_profile };

    if (this.profile.new) {
      this.current_profile = { ...this.default_profile };
    } else {
      this.current_profile = { ...this.profile };
    }
    this.current_profile.other.all_agencies =
      this.current_profile.other.all_agencies == "True" ? true : false;
  },

  methods: {
    removeAction(policy, action) {
      this.current_profile.policies[policy] = this.current_profile.policies[
        policy
      ].filter((item) => item !== action);
    },
    addAction(policy, action) {
      console.log(this.current_profile.policies)
      if (!this.current_profile.policies[policy].includes(action)) {
        this.current_profile.policies[policy].push(action);
              console.log(this.current_profile.policies)

        this.current_profile.policies[policy] = this.current_profile.policies[
          policy
        ].sort((a, b) => (a > b ? 1 : -1));
      }
    },
    saveProfil() {
      let profile = { ...this.current_profile };
      try {
        let endpoint = "";
        if (profile.id) {
          endpoint = "users/profiles/" + profile.id;
          delete profile.id;
        } else {
          endpoint = "users/profiles";
        }
        profile.other.all_agencies = profile.other.all_agencies
          ? "True"
          : "False";
        HTTP.post(endpoint, profile).then(
          function (response) {
            console.log(response);
            if (response.status == 200) {
              this.current_profile = { ...this.default_profile };
              this.$emit("profileSaved");
            } else {
              console.log("on alerte");
            }
          }.bind(this)
        );
      } catch (error) {
        console.log("on alerte");
      }
    },
  },
};
</script>

<style>
</style>