<template>
<div>
    <div class="row " v-if="true">
      <div class="col mb-3">
        <div class="card">
          <div class="card-body">
            <input
              class="form-control me-2"
              v-model="search"
              type="search"
              placeholder="Rechercher (ICCID)"
              aria-label="Search"
            />
          </div>
        </div>
      </div>
    </div>
  <div class="card">
    <div class="card-header">
      Inventaire des SIM
    </div>
    <div class="card-body">
      <div class="row">
        <div calss="col">
          <table class="table">
            <thead>
              <tr class="tr-class-1 pointer">
                <th @click="orderField('iccid')">ICCID</th>
                <th @click="orderField('msisdn')">MSISDN</th>
                <th @click="orderField('type')">Opérateur</th>
                <th @click="orderField('userId')">Transmetteur</th>
                <th @click="orderField('status')">Statut</th>
                <th v-if="accessCheck('sims', 'update')">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="sim in shown_sims" :key="sim.iccid">
                <td v-if="sim.iccid == ''">
                  <input
                    id="iccid"
                    v-model="new_iccid"
                    class="form-control"
                    required
                  />
                </td>
                <td v-else>{{ sim.iccid }}</td>

                <td v-if="sim.iccid == changed_sim.iccid">
                  <input
                    id="msisdn"
                    v-model="sim.msisdn"
                    class="form-control"
                    required
                  />
                </td>
                <td v-else>
                  {{ sim.msisdn }}
                </td>
                <td v-if="sim.iccid == changed_sim.iccid">
                  <select
                    id="type"
                    v-model="sim.type"
                    class="form-select no-padding"
                    aria-label="MSISDN"
                    required
                  >
                    <option v-for="type in simType" :key="type" :value="type">
                      {{ type }}
                    </option>
                  </select>
                </td>
                <td v-else>{{ sim.type }}</td>

                <td v-if="changed_sim.iccid == sim.iccid">
                  <input
                    id="userId"
                    v-model="sim.userId"
                    class="form-control"
                    required
                  />
                </td>
                <td v-else>{{ sim.userId }}</td>
                <td>{{ sim.status }}</td>

                <td v-if="(changed_sim.iccid != sim.iccid) &&  accessCheck('sims', 'update')">
                  <!-- Bouton pour éditer une sim -->
                  <button
                    v-on:click="chooseSim(sim)"
                    type="button"
                    class="btn btn-outline-primary btn-sm"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-pencil"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"
                      />
                    </svg>
                  </button>
                </td>
                <td v-if="(changed_sim.iccid == sim.iccid) &&  accessCheck('sims', 'update') || (changed_sim.new &&  accessCheck('sims', 'create'))">
                  <!--  Bouton pour valider l'édition -->
                  <div
                    class="btn-group"
                    role="group"
                    aria-label="Basic example"
                  >
                    <button
                      v-on:click="editSim(sim)"
                      type="button"
                      class="btn btn-primary btn-sm btn-success"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-check"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
                        />
                      </svg>
                    </button>
                    <button
                      v-on:click="editSim()"
                      type="button"
                      class="btn btn-outline-primary btn-sm"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-x"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"
                        />
                      </svg>
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col"></div>
        <div class="col-md-auto">
          <button
            type="button"
            class="btn btn-outline-primary"
            v-on:click="createSim()"
             v-if="accessCheck('sims', 'create')"
          >
            Nouveau
          </button>
        </div>
      </div>
      <pageSelector
        :per_page_options="per_page_options"
        :per_page="per_page"
        :page="page"
        :pages="pages"
        @choosen-page="goToPage"
        @per-page="showPerPage"
      ></pageSelector>
    </div>
  </div></div>
</template>

<script>
import { HTTP } from "../../middleware/http-common";
import getPages from "../../mixins/manageList";
import orderList from "../../mixins/manageList";
import pagination from "../../mixins/manageList";
import filterList from "../../mixins/manageList";
import pageSelector from "../../components/pageSelector";

import accessCheck from "../../mixins/accessCheck.js"


export default {
  mixins: [orderList, getPages, pagination, filterList, accessCheck],
  components: {
    pageSelector,
  },

  data() {
    return {
      search: "",
      simType: ["generic", "networth"],
      order: true,
      page: 1,
      per_page: 50,
      per_page_options: [10, 50, 100],
      changed_sim: {},
      new_iccid: "",
      new_sim: {
        new: true /*  */,
        iccid: "",
        msisdn: "",
        status: "",
        userId: "",
        type: "",
      },
    };
  },
  computed: {
    listSims() {
      return this.$store.state.sims;
    },
    agencies() {
      return this.$store.state.agencies;
    },
    profiles() {
      return this.$store.state.profiles;
    },
    shown_sims() {
      this.pagination(this.listSims, this.per_page, this.page);
      this.filtered_sims;
      let sims = this.pagination(this.filtered_sims, this.per_page, this.page);
      return sims;
    },
    filtered_sims() {
      return this.filterList(this.listSims, ["iccid"], this.search);
    },
    pages() {
      return this.getPages(this.listSims, this.per_page);
    },
  },
  created() {
    this.$store.dispatch("fetchSims");
  },
  methods: {
    createSim() {
      this.changed_sim = { ...this.new_sim };
      this.listSims.unshift(this.changed_sim);
    },
    editSim(sim = null) {
      if (sim) {
        try {
          let endpoint = "";
          if (sim.new) {
            delete sim.new;
            sim.iccid = this.new_iccid;
            endpoint = "sims";
          } else {
            endpoint = "sims/" + sim.iccid;
            delete sim.iccid;
          }
          HTTP.post(endpoint, sim).then(
            function (response) {
              if (response.status == 200) {
                this.$store.dispatch("fetchSims");
              } else {
                console.log("on alerte");
              }
            }.bind(this)
          );
        } catch (error) {
          console.log("on alerte");
        }
      }
      this.changed_sim = {};
    },
    orderField(field) {
      this.orderList(this.listSims, field, !this.order);
      this.order = !this.order;
    },
    chooseSim(sim) {
      this.changed_sim = sim;
    },
    goToPage(page) {
      this.page = page;
    },
    showPerPage(per_page) {
      this.per_page = per_page;
      this.page = 1;
    },
  },
};
</script>
