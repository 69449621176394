<template>
  <div>
    <div class="row " v-if="collapse_show">
      <div class="col mb-3">
          <beaconEdit
            :beacon="changed_beacon"
            :key="changed_beacon.ident"
            @endEdit="endEdit"
          ></beaconEdit>
      </div>
    </div>
    <div class="row " v-if="!collapse_show">
      <div class="col mb-3">
        <div class="card">
          <div class="card-body">
            <input
              class="form-control me-2"
              v-model="search"
              type="search"
              placeholder="Rechercher (UUID, Name)"
              aria-label="Search"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="card">
    <div class="card-header">
      Inventaire de balises
    </div>
      <div class="card-body">
        <div class="row">
          <div calss="col">
            <table class="table">
              <thead>
                <tr class="tr-class-1 pointer">
                  <th @click="orderField('ident')">UUID</th>
                  <th @click="orderField('msisdn')">Name</th>
                  <th @click="orderField('type')">Type</th>
                  <th @click="orderField('agency')">Agence</th>
                  <th @click="orderField('status')">Statut</th>
                  <th >Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="beacon in shown_beacons" :key="beacon.ident">
                  <td>{{ beacon.ident }}</td>

                  <td>
                    {{ beacon.name }}
                  </td>
                  <td>{{ beacon.type }}</td>

                  <td>{{ beacon.agency }}</td>
                  <td>{{ beacon.status }}</td>

                  <td>
                    <button v-if="accessCheck('beacons', 'update')"
                      v-on:click="chooseBeacon(beacon)"
                      type="button"
                      class="btn btn-outline-primary btn-sm"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-pencil"
                        viewBox="0 0 16 16"
                      >
                        <path
                          d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"
                        />
                      </svg>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row">
          <div class="col"></div>
          <div class="col-md-auto">
            <button
              type="button"
              class="btn btn-outline-primary"
              v-on:click="createBeacon()"
              v-if="accessCheck('beacons', 'create')"
            >
              Nouveau
            </button>
          </div>
        </div>
        <pageSelector
          :per_page_options="per_page_options"
          :per_page="per_page"
          :page="page"
          :pages="pages"
          @choosen-page="goToPage"
          @per-page="showPerPage"
        ></pageSelector>
      </div>
    </div>
  </div>
</template>

<script>
import getPages from "../../mixins/manageList";
import orderList from "../../mixins/manageList";
import pagination from "../../mixins/manageList";
import filterList from "../../mixins/manageList";
import pageSelector from "../../components/pageSelector";
import beaconEdit from "./beaconEdit";

import accessCheck from "../../mixins/accessCheck.js"


export default {
  mixins: [orderList, getPages, pagination, filterList, accessCheck],
  components: {
    pageSelector,
    beaconEdit,
  },

  data() {
    return {
      search: "",
      beaconType: ["generic", "networth"],
      order: true,
      page: 1,
      per_page: 50,
      per_page_options: [10, 50, 100],
      changed_beacon: {},
      new_ident: "",
      new_beacon: {
        new: true,
        ident: "Nouveau",
        name: "Beacon",
        type: "",
        agency: "",
        agency_id: 1,
        status: "",
        posLatitude: 0,
        posLongitude: 0,
        posText: "Balise Beacon",
        comment: "",
      },
      collapse_show: false,
    };
  },
  computed: {
    listBeacons() {
      return this.$store.state.beacons;
    },
    agencies() {
      return this.$store.state.agencies;
    },
    shown_beacons() {
      this.pagination(this.listBeacons, this.per_page, this.page);
      this.filtered_beacons;
      let beacons = this.pagination(
        this.filtered_beacons,
        this.per_page,
        this.page
      );
      return beacons;
    },
    filtered_beacons() {
      return this.filterList(this.listBeacons, ["ident", "name"], this.search);
    },
    pages() {
      return this.getPages(this.listBeacons, this.per_page);
    },
  },
  created() {
    this.$store.dispatch("fetchBeacons");
  },
  methods: {
    createBeacon() {
      this.changed_beacon = { ...this.new_beacon };
      this.collapse_show = true;
    },
    endEdit() {
      this.collapse_show = false;
      this.changed_beacon = {};
    },
    orderField(field) {
      this.orderList(this.listBeacons, field, !this.order);
      this.order = !this.order;
    },
    chooseBeacon(beacon) {
      this.changed_beacon = beacon;
      this.collapse_show = true;
    },
    goToPage(page) {
      this.page = page;
    },
    showPerPage(per_page) {
      this.per_page = per_page;
      this.page = 1;
    },
  },
};
</script>
