<template>
  <div>
    <div class="row">
      <div class="col"><h1>Transmetteurs</h1></div>
      <div class="col-md-auto">
        <!-- Button trigger modal -->
        <button
          v-if="!showEdit && accessCheck('devices', 'create')"
          type="button"
          class="btn btn-primary"
          v-on:click="createDevice()"
        >
          Ajouter matériel
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div v-if="showResult" class="mb-3">
          <deviceResult
            :devicesRetour="devicesRetour"
            :key="devicesRetour.ident"
            @hideDeviceResult="showResult = false"
            @editReturn="returnTreatment"
          ></deviceResult>
        </div>
        <div v-if="showEdit" class="mb-3">
          <deviceEdit
            :device="selectedDevice"
            :key="selectedDevice.ident"
            @endEdit="endEdit"
          ></deviceEdit>
        </div>
        <deviceList
          v-if="showList"
          :key="devices.ident"
          @selectedDevice="editDevice"
        ></deviceList>
      </div>
    </div>
  </div>
</template>

<script>
import deviceEdit from "../components/devices/deviceEdit";
import deviceList from "../components/devices/deviceList";
import deviceResult from "../components/devices/deviceResult";

import accessCheck from "../mixins/accessCheck.js"


export default {
  metaInfo: {
    title: "Transmetteurs",
  },
  mixins: [accessCheck],
  components: {
    deviceEdit,
    deviceList,
    deviceResult,
  },
  data() {
    return {
      devices: [],
      posts: [],
      errors: [],
      devicesType: [],
      agencies: [],
      selectedDevice: {},
      deviceDefault: {
        ident: "",
        iccid: "",
        entity: "",
        comment: "",
        type_id: 0,
        agency_id: 0,
        status: "initial",
        new: true,
      },
      showEdit: false,
      showResult: false,
      showList: true,
      alert: {
        title: "Information",
        show: false,
        type: "alert-primary",
        msg: "Default Message",
      },
      devicesRetour: [],
      //à suppriemr
      msgRetour: [],
    };
  },

  // Fetches posts when the component is created.
  created() {},

  methods: {
    endEdit(retours) {
      this.showEdit = false;
      this.selectedDevice = {};
      this.showList = true
      console.log(retours)
    },
    createDevice() {
      this.selectedDevice = { ...this.deviceDefault };
      this.showEdit = true;
      this.showResult = false;
    },
    editDevice(device) {
      this.selectedDevice = { ...device };
      this.showEdit = true;
      this.showList = false;
    },
    returnTreatment(msgRetour) {
      for (let retour of msgRetour) {
        if (retour.status == 200) {
          retour.class = "";
        } else {
          retour.class = "table-warning";
        }
      }
      this.devicesRetour = msgRetour;
      this.showResult = false;
      this.showList = true;
      this.showEdit = false;
    },
  },
};
</script>
