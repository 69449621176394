<template>
  <div class="card">
    <div class="card-header">
      <div class="row">
        <div class="col" v-if="edit_device.new">Création d'un terminal</div>
        <div class="col" v-if="!edit_device.new">Edition d'un terminal</div>
        <div class="col-md-auto">
          <button
            type="button"
            class="btn btn-outline-secondary btn-sm"
            v-if="edit_device.new"
            @click="batchCreate = !batchCreate"
          >
            Creation Multiligne
          </button>
        </div>
        <div class="col-md-auto">
          <button
            type="button"
            class="btn-close float-end"
            @click="editDevice()"
          ></button>
        </div>
      </div>
    </div>
    <div class="card-body">
      <form class="row mb-3">
        <!-- BATCH INSERT -->
        <div v-if="batchCreate" class="col-md-12">
          <label
            for="multiIdent"
            class="form-label"
            data-toggle="tooltip"
            data-placement="top"
            title="Un identifiant par ligne. Ajouter ';ICCID' pour intégration directe de l'ICCID"
            >Liste d'identifiants uniques et ICCID (optionel)</label
          >
          <textarea
            class="form-control"
            id="multiIdent"
            rows="10"
            data-toggle="tooltip"
            data-placement="top"
            title="Un identifiant par ligne"
            placeholder="IDENT[;ICCID]
IDENT[;ICCID]
..."
            v-model="edit_device.multiIdent"
          ></textarea>
        </div>
        <!-- IDENT -->
        <div v-if="!batchCreate" class="col-md-6">
          <label for="ident" class="form-label"
            >Identifiant unique du matériel (IMEI)</label
          >
          <input
            id="ident"
            v-model="edit_device.ident"
            class="form-control"
            :disabled="!edit_device.new"
            :readonly="!edit_device.new"
          />
        </div>
        <div v-if="!batchCreate" class="col-md-6">
          <!-- ICCID -->
          <label for="iccid" class="form-label">ICCID de la SIM</label>
          <input id="iccid" v-model="edit_device.iccid" class="form-control" />
        </div>
        <!-- TYPE -->
        <div class="col-md-6">
          <label for="devicesType" class="form-label">Type de matériel</label>
          <select
            id="devicesType"
            v-model="edit_device.type_id"
            class="form-select"
            aria-label="Type de device"
            required
          >
            <option
              v-for="type in devices_type"
              :value="type.id"
              :key="type.id"
            >
              {{ type.manufacturer }} - {{ type.name }}
            </option>
          </select>
        </div>
        <div class="col-md-6">
          <label for="devicesStatus" class="form-label">Statut</label>
          <select
            id="devicesStatus"
            v-model="edit_device.status"
            class="form-select"
            aria-label="Statut du matériel"
            required
          >
            <option
              v-for="status in devices_status"
              :value="status"
              :key="status"
            >
              {{ status }}
            </option>
          </select>
        </div>
        <!-- AGENCIES -->
        <div class="col-md-6">
          <label for="agencies" class="form-label">Agence</label>
          <select
            id="agencies"
            v-model="edit_device.agency_id"
            class="form-select"
            aria-label="Agence"
            required
          >
            <!-- <option selected>...</option> -->
            <option
              v-for="agence in agencies"
              :value="agence.id"
              :key="agence.id"
            >
              {{ agence.name }}
            </option>
          </select>
        </div>
        <div class="col-md-6">
          <!-- ENTITY -->
          <label for="entity" class="form-label"
            >Entité d'aggregation (optionel)</label
          >
          <input
            id="entity"
            v-model="edit_device.entity"
            class="form-control"
          />
        </div>
        <!-- COMMENT -->
        <div class="col-md-12">
          <label for="comment" class="form-label">Commentaire</label>
          <textarea
            class="form-control"
            id="comment"
            v-model="edit_device.comment"
          ></textarea>
        </div>
      </form>
      <div class="row">
        <div class="col"></div>
        <div class="inline pull-left col-md-auto">

          <button
            class="btn btn-outline-primary me-2"
            v-on:click="editDevice()"
            type="button"
          >
            Annuler
          </button>

          <button
            class="btn btn-primary ms-2"
            v-on:click="editDevice(edit_device)"
            type="button"
          >
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "../../middleware/http-common";

export default {
  props: ["device"],
  data() {
    return {
      devices_status: ["initial", "active", "suspended", "test", "disable"],
      batchCreate: false,
      edit_device: {},
    };
  },
  beforeCreate() {
    this.$store.dispatch("fetchDeviceTypes")
    this.$store.dispatch("fetchAgencies");

  },
  mounted() {
    //get device to edit
    this.edit_device = { ...this.device };
  },
  computed: {
    agencies() {
      return this.$store.getters.getAgencies;
    },
    devices_type() {
      return this.$store.getters.getDeviceTypes;
    },
  },
  methods: {
    editDevice(device = null) {

    if(device == null) {
      this.$store.dispatch("change_loading_status", false)
      this.$emit("endEdit")
    }else{
      
      this.$store.dispatch("change_loading_status", true);
    }
      
    let retours = [];
    let devices = this.prepareDevices(device);
    let endpoint = "";
    console.log(this.batchCreate)
    if (this.batchCreate == true) {
      batch_device(devices, endpoint)
    }else{
      for (let device of devices){
        post_device(device, endpoint)
      }
    }

    setTimeout(() => {  this.$store.dispatch("change_loading_status", false); this.$store.dispatch("fetchDevices"); this.$emit("endEdit", retours) }, 3000);

      function post_device(device, endpoint) {
        var retour="";
        if(device.new) {
          delete device.new;
          delete device.agency;
          endpoint="devices";
        } else {
          endpoint="devices/"+device.ident;
          delete device.ident;
          delete device.userId;
          delete device.type;
          delete device.msisdn;
          delete device.agency;
          delete device.type_id;
        }
        HTTP.post(endpoint, device)
          .then(function(response) {
            retour={
              status: response.status,
              statusText: response.statusText,
              payload: response.data,
            };
            console.log(retour);
            retours.push(retour);
          })
          .catch((error) => {
            retour={
              status: error.response.status,
              statusText: error.response.statusText,
              payload: error.response.data,
            };
            retours.push(retour);
          });
        return retour
      }

      function batch_device(devices, endpoint) {
        var retour="";
        endpoint="devices";
        HTTP.post(endpoint, devices)
          .then(function(response) {
            retour={
              status: response.status,
              statusText: response.statusText,
              payload: response.data,
            };
            console.log(retour);
            retours.push(retour);
          })
          .catch((error) => {
            retour={
              status: error.response.status,
              statusText: error.response.statusText,
              payload: error.response.data,
            };
            retours.push(retour);
          });
        return retour
      }

    
    },
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    prepareDevices(device) {
      // manage multi Ident send by creating an array a device to send
      let devices = [];
      let idents = [];
      if (this.batchCreate) {
        //get lines
        let vals = device.multiIdent.split("\n").filter(Boolean);
        //split IMEI and ICCID in lines
        for (let str of vals) {
          let values = str.split(";");
          //stock iccid and values
          idents.push(values);
        }
        console.log(idents)
        // create device object with common main attributes and specific iccid and ident
        for (let ident of idents) {
          let newDevice = {};
          newDevice = { ...device };
          newDevice.ident = ident[0];
          newDevice.iccid = typeof ident[1] != "undefined" ? ident[1] : "";
          delete newDevice.multiIdent;
          devices.push(newDevice);
        }
      } else {
        //si qu'une device on a un tableau d'un element
        devices.push(device);
      }
      return devices;
    },
  },
};
</script>
