import Vue from 'vue'
import Keycloak from 'keycloak-js'

// const options = {
//   url: 'https://auth.prod.uconnect.rs2d.tech/auth/',
//   realm: 'u-connect',
//   clientId: 'cli',
//   secret: "e9bd6cb5-8d59-43bf-a019-959a87edaed4"

// }
    //"url": "https://keycloak.moodle.pv.vm.rs2d.tech/",

const options = {
    "realm": "presenceverte",
    "url": "https://auth.pvsa.fr/",
    "clientId": 'front-inventory-app'
}

const _keycloak = Keycloak(options)

const Plugin = {
    install(Vue) {
        Vue.$keycloak = _keycloak
    }
}

Plugin.install = Vue => {
    Vue.$keycloak = _keycloak
    Object.defineProperties(Vue.prototype, {
        $keycloak: {
            get() {
                return _keycloak
            }
        }
    })
}

Vue.use(Plugin)

export default Plugin
