<template>
  <div>
    <div class="row">
      <div class="col"><h1>Cartes SIM</h1></div>
    </div>
    <div class="row">
      <div class="col">
        <simList></simList>
      </div>
    </div>
  </div>
</template>


<script>

import simList from "../components/sims/simList";

export default {
    metaInfo: {
    title: "SIM",
  },
  components: {
    simList,
  },
};
</script>
