<template>
  <div class="card">
    <div class="card-header">Edition d'une balise</div>
    <div class="card-body">
      <form class="row mb-3">
        <!-- UUID -->
        <div class="col-md-6">
          <label for="ident" class="form-label"
            >Identifiant unique du matériel (UUID)</label
          >
          <input
            id="ident"
            v-model="edit_beacon.ident"
            class="form-control"
            :disabled="!edit_beacon.new"
            :readonly="!edit_beacon.new"
          />
        </div>
        <div class="col-md-6">
          <!-- NAME -->
          <label for="name" class="form-label">Nom</label>
          <input id="name" v-model="edit_beacon.name" class="form-control" />
        </div>
        <!-- TYPE -->
        <div class="col-md-4">
          <label for="type" class="form-label">Type de balise</label>
          <select
            id="devicesType"
            v-model="edit_beacon.type"
            class="form-select"
            aria-label="Type de device"
            required
          >
            <option v-for="type in beacon_type" :value="type" :key="type">
              {{ type }}
            </option>
          </select>
        </div>
        <div class="col-md-4">
          <label for="devicesStatus" class="form-label">Statut</label>
          <select
            id="devicesStatus"
            v-model="edit_beacon.status"
            class="form-select"
            aria-label="Statut du matériel"
            required
          >
            <option
              v-for="status in devicesStatus"
              :value="status"
              :key="status"
            >
              {{ status }}
            </option>
          </select>
        </div>
        <div class="col-md-4">
          <label for="agencies" class="form-label">Agence</label>
          <select
            id="agencies"
            v-model="edit_beacon.agency_id"
            class="form-select"
            aria-label="Agence"
            required
          >
            <option
              v-for="agence in agencies"
              :value="agence.id"
              :key="agence.id"
            >
              {{ agence.name }}
            </option>
          </select>
        </div>
        <div class="col-md-4">
          <!-- NAME -->
          <label for="posLatitude" class="form-label">Latitude</label>
          <input
            id="posLatitude"
            v-model="edit_beacon.posLatitude"
            class="form-control"
          />
        </div>
        <div class="col-md-4">
          <!-- NAME -->
          <label for="posLongitude" class="form-label">Longitude</label>
          <input
            id="posLongitude"
            v-model="edit_beacon.posLongitude"
            class="form-control"
          />
        </div>
        <div class="col-md-4">
          <!-- NAME -->
          <label for="posText" class="form-label">Texte Position</label>
          <input
            id="posText"
            v-model="edit_beacon.posText"
            class="form-control"
          />
        </div>
        <!-- COMMENT -->
        <div class="col-md-12">
          <label for="comment" class="form-label">Commentaire</label>
          <textarea
            class="form-control"
            id="comment"
            v-model="edit_beacon.comment"
          ></textarea>
        </div>
      </form>
      <div class="row">
        <div class="col"></div>
        <div class="inline pull-left col-md-auto">
          <button
            class="btn btn-outline-primary me-2"
            v-on:click="editBeacon()"
            type="button"
          >
            Annuler
          </button>
          <button
            class="btn btn-primary ms-2"
            v-on:click="editBeacon(edit_beacon)"
            type="button"
          >
            Enregistrer
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "../../middleware/http-common";

export default {
  props: ["beacon"],

  data() {
    return {
      edit_beacon: {},
      beacon_type: ["iBKS105_Eddystone", "WIFI"],
      devicesStatus: ["initial", "active", "suspended", "test", "disable"],
    };
  },
  beforeCreate() {
    this.$store.dispatch("fetchAgencies");
  },
  created() {
    this.edit_beacon = { ...this.beacon };
  },
  computed: {
    agencies() {
      return this.$store.state.agencies;
    },
  },
  methods: {
    editBeacon(beacon = null) {
      if (beacon) {
        try {
          let endpoint = "";
          if (beacon.new) {
            delete beacon.new;
            delete beacon.agency;
            endpoint = "beacons";
          } else {
            endpoint = "beacons/" + beacon.ident;
            delete beacon.ident;
            delete beacon.agency;
          }
          HTTP.post(endpoint, beacon).then(
            function (response) {
              if (response.status == 200) {
                this.$store.dispatch("fetchBeacons");
              } else {
                console.log("on alerte");
              }
            }.bind(this)
          );
        } catch (error) {
          console.log("on alerte");
        }
      }
      this.$emit("endEdit");
    },
  },
};
</script>
