export default {
    computed: {
        user() {
            return this.$store.getters.getUser;
        },
    },
    methods: {
        accessCheck(resource, action = 'read') {
            if (this.user.allow && typeof this.user.policies.policies[resource] != 'undefined') {
                return this.user.policies.policies[resource].includes(action)
            } else {
                return false
            }
        }
    },
}