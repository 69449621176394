var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{},[_c('nav',{staticClass:"navbar navbar-expand-md navbar-light fixed-top bg-light"},[_c('div',{staticClass:"container-fluid"},[_c('a',{staticClass:"navbar-brand",attrs:{"href":"#"}},[_vm._v("PV Inventaire")]),_vm._m(0),_c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navbarCollapse"}},[_c('ul',{staticClass:"navbar-nav me-auto mb-2 mb-md-0"},[_c('li',{staticClass:"nav-item active"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/"}},[_vm._v("Accueil")])],1),(_vm.accessCheck('devices'))?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/devices"}},[_vm._v("Transmetteurs")])],1):_vm._e(),(_vm.accessCheck('sims'))?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/sims"}},[_vm._v("SIM")])],1):_vm._e(),(_vm.accessCheck('beacons'))?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/beacons"}},[_vm._v("Balises")])],1):_vm._e(),(
              _vm.accessCheck('users') ||
              _vm.accessCheck('devicesType') ||
              _vm.accessCheck('profiles') ||
              _vm.accessCheck('agencies')
            )?_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link",attrs:{"to":"/admin"}},[_vm._v("Admin")])],1):_vm._e()]),_c('div',{staticClass:"d-flex"},[_c('button',{staticClass:"btn btn-outline"},[_c('router-link',{staticClass:"nav-item nav-link",attrs:{"to":"/user"}},[_vm._v(_vm._s(_vm.user.full_name))])],1)])])])]),(_vm.loadingStatus)?_c('div',{staticClass:"text-center"},[_vm._m(1)]):_vm._e(),_c('main',{staticClass:"container"},[_c('div',{staticClass:"bg-light p-5"},[_c('router-view')],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-bs-toggle":"collapse","data-bs-target":"#navbarCollapse","aria-controls":"navbarCollapse","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('span',{staticClass:"navbar-toggler-icon"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"spinner-border",attrs:{"role":"status"}},[_c('span',{staticClass:"visually-hidden"},[_vm._v("Loading...")])])
}]

export { render, staticRenderFns }