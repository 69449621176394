<template>
  <div>
    <div class="row">
      <div class="col"><h1>User info</h1></div>
    </div>
    <div class="row">
      <div class="col">
        <div class="card">
          <div class="card-header">Profil utilisateur</div>
          <div class="card-body">
            <div class="row">
              <div class="col">
                <h5 class="card-title">{{ user.full_name }}</h5>
                <h6 class="card-subtitle mb-2 text-muted">
                  {{ user.kc_full_name }}
                </h6>
                <p class="card-text" v-if="user.email_verified">
                  Email vérifié : Oui
                </p>
                <p class="card-text" v-else>Email vérifié : Non</p>
                <p class="card-text">Statut : {{ user.status }}</p>
                <p class="card-text" v-if="user.policies.other">
                  Agence : Toutes
                </p>
                <p class="card-text" v-if="!user.policies.other">
                  Agence : {{ user.agency }}
                </p>
                <p class="card-text">Profil : {{ user.profile }}</p>
              </div>
            </div>
            <div class="row mt-3">
              <div class="col">
                <button type="button" class="btn btn-primary" @click="logout">Déconnexion</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
// import { HTTP } from "../middleware/http-common";

export default {
    metaInfo: {
    title: "Utilisateur",
  },
  data() {
    return {};
  },
  created() {},
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
  methods: {
    logout() {
      this.$keycloak.logout()
      this.$store.dispatch("logoutUser");
    }
  },
};
</script>
