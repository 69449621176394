// import { createRouter, createWebHistory } from 'vue-router'
import Vue from 'vue'
import VueRouter from 'vue-router'

import AdminView from '../views/AdminView.vue'
import HomeView from '../views/HomeView.vue';
import DevicesView from '../views/DevicesView.vue'
import SimsView from '../views/SimsView.vue'
import BeaconsView from '../views/BeaconsView.vue'
import User from '../views/UserView.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },

  {
    path: '/admin/:page?',
    name: 'Admin',
    component:AdminView
  },
  {
    path: '/beacons',
    name: 'Beacons',
    component:BeaconsView
  },
  {
    path: '/sims',
    name: 'Sims',
    component:SimsView
  },
  {
    path: '/devices/:ident(\\d+)?',
    name: 'Devices',
    component:DevicesView
  },
  {
    path: '/user',
    name: 'User',
    component:User
  }
]

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes
})

export default router

// const router = createRouter({
//   history: createWebHistory(process.env.BASE_URL),
//   routes
// })

// export default router