<template>
  <div>
    <div class="row">
      <div class="col"><h1>Balises</h1></div>
    </div>
    <div class="row">
      <div class="col">
        <beaconList></beaconList>
      </div>
    </div>
  </div>
</template>


<script>

import beaconList from "../components/beacons/beaconList";

export default {
    metaInfo: {
    title: "Balises",
  },
  components: {
    beaconList,
  },
};
</script>
