<template>
  <div style="text-align: center;">
    <div style="max-width: 75px; float:left">
      <select
        id="perPage"
        class="form-select"
        aria-label="Nombre de device par page"
        required
        @change="onChange($event)"
      >
        <option
          v-for="nb_per_page in per_page_options"
          :value="nb_per_page"
          :key="nb_per_page"
        >
          {{ nb_per_page }}
        </option>
      </select>
    </div>

    <div class="col-md-auto" style="margin-right: 75px;">
      <div class="btn-group" role="group" aria-label="Pager">
        <button
          @click="goToFirstPage()"
          type="button"
          class="btn"
          v-bind:class="{
            'btn-outline-primary': page,
          }"
        >
          First
        </button>
        <button
          @click="goToPreviousPage(page)"
          type="button"
          class="btn"
          v-bind:class="{
            'btn-outline-primary': page,
          }"
        >
          Previous
        </button>


        <button
          v-for="lapage in charge_ten_array(page, pages)"
          :key="lapage"
          @click="goToPage(lapage)"
          type="button"
          class="btn"
          v-bind:class="{
            'btn-secondary': lapage == page,
            'btn-outline-primary': lapage != page,
          }"
        >
          {{ lapage }}
        </button>


        <button
          @click="goToNextPage(page, pages)"
          type="button"
          class="btn"
          v-bind:class="{
            'btn-outline-primary': page,
          }"
        >
          Next
        </button>
        <button
          @click="goToLastPage(pages)"
          type="button"
          class="btn"
          v-bind:class="{
            'btn-outline-primary': page,
          }"
        >
          Last
        </button>
      </div>
      <div class="col-md-auto"></div>
    </div>

  </div>
</template>
<script>
export default {
  props: ["per_page_options", "per_page", "page", "pages"],
  methods: {
    goToNextPage(page, pages) {
      if(page != pages.length){
        page = page + 1
      }
      this.$emit("choosen-page", page);
    },
    goToPreviousPage(page) {
      if(page != 1){
        page = page -1
      }
      this.$emit("choosen-page", page);
    },
    goToFirstPage() {
      this.$emit("choosen-page", 1);
    },
    goToLastPage(pages) {
      this.$emit("choosen-page", pages.length);
    },
    goToPage(page) {
      this.$emit("choosen-page", page);
    },
    onChange(event) {
      this.$emit("per-page", event.target.value);
    },
    charge_ten_array(page, pages) {
      var array = []
      if(page < 6){
        array = pages.slice(0,10)
      }
      else if(page > 5 && page < pages.length - 5){
        array = pages.slice(page - 5, page + 5)
      }
      else if(page > pages.length - 6)
        array = pages.slice(pages.length - 10, pages.length)
      return array
    },
  },
};
</script>

<style>

</style>
