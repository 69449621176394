import { render, staticRenderFns } from "./profileModal.vue?vue&type=template&id=1564670a&"
import script from "./profileModal.vue?vue&type=script&lang=js&"
export * from "./profileModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports