import axios from 'axios';

export const HTTP = axios.create({
  // baseURL: `https://api-dev.pv-connect.fr/api/`,
  // headers: {
  //   token: 'fecbcf38817087ddfeb2f6ee9c796d2102e4a7291dad024d2c1f1fe676318b42'
  // }
  // baseURL: `http://dev01.rs2d.tech:5000/api/`,
  baseURL: `https://api.pv-connect.fr/api/`,
  headers: {
   // token: '1e7935c51a32fea0c75bdf22459c6d751ee2ce6be4b4bfa29cfced60a53c0798'
  }

  // baseURL: `https://api.pv-connect.fr/api/`,
  // headers: {
  //   token: '0a0808869090231e257bab026db62253537b3fbae0cc225c3ee611b2a3234dc1'
  // }
})
// HTTP.defaults.headers.post['Content-Type'] ='application/x-www-form-urlencoded';
