<template>
  <div class="card">
    <div class="card-body">
      <div class="row row-cols-1 row-cols-md-4 g-2">
        <div class="col" v-for="deviceType in agencies" :key="deviceType.name">
          <div class="card type-card">
            <div class="card-body">
              <h5 class="card-title">
                {{ deviceType.name }}
              </h5>
            </div>
          </div>
        </div>
        <div class="col" v-if="showCreate">
          <div class="card type-card">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title">
                    <input
                      id="name"
                      v-model="new_agency.name"
                      class="form-control form-control-sm"
                      placeholder="Nom"
                      required
                    />
                  </h5>
                </div>
                <div class="col-md-auto">
                  <button
                    v-on:click="createAgency()"
                    type="button"
                    class="btn btn-primary btn-sm btn-success"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-check"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col" v-if="showNew">
          <div class="card type-card">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <h5 class="card-title">Nouveau</h5>
                </div>
                <div class="col-md-auto">
                  <button
                    v-on:click="newAgency()"
                    type="button"
                    class="btn btn-outline-primary btn-sm"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-pencil"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.pointer {
  cursor: pointer;
}
input {
  color: #000;
  background: crimson;
  padding: 0;
}
.image {
  text-align: center;
  max-width: 100%;
  width: 100%; /* IE */
}
.type-card {
  background-color: ghostwhite;
}
</style>

<script>
import { HTTP } from "../../middleware/http-common";

export default {
  data() {
    return {
      order: {
        name: "down",
      },
      showCreate: false,
      showNew: true,
      new_agency: {},
      default_agency: {
        name: "",
      },
    };
  },
  beforeCreate() {
    this.$store.dispatch("fetchAgencies");
  },
  computed: {
    agencies() {
      let agences = this.$store.state.agencies;
      const field = "name";
      agences = agences.sort((a, b) => (a[field] > b[field] ? 1 : -1));
      return agences;
    },
  },
  methods: {
    getList() {
      //get types
      this.$store.dispatch("fetchAgencies");
    },
    newAgency() {
      this.showNew = false;
      this.showCreate = true;
      this.new_agency = { ...this.default_agency };
    },
    createAgency() {
      try {
        let endpoint = "agencies";

        HTTP.post(endpoint, this.new_agency).then(
          function (response) {
            console.log(response);
            if (response.status == 200) {
              this.new_agency = {};
              this.getList();
            } else {
              console.log("on alerte");
            }
          }.bind(this)
        );
      } catch (error) {
        console.log("on alerte");
      }
    },
  },
};
</script>
